import type { AppLoadContext } from "@remix-run/node"
import { json, redirect } from "@remix-run/node"
import { headers } from "~/utils/cors.server"
import { commitSessionID, getApiUser, getSessionID, getUser } from "~/utils/session.server"
import { DB } from "./db.server"
import { type Params, isRouteErrorResponse } from "@remix-run/react"
import uuid from "uuid"

type Args = {
  request: Request
  response: Response
  params: Params
  context: AppLoadContext
}

export function addUserHeaders(context: AppLoadContext, response: Response) {
  if (context.user) {
    if (context.masquerador) {
      response.headers.set("X-Masquerador-ID", String(context.masquerador.UserID))
    }
    response.headers.set("X-User-ID", String(context.userID))
    response.headers.set("X-User", String(context.user.UserName))
    response.headers.set("X-User-Email", String(context.user.Email))
  }
  return response
}

function userLogger(context: AppLoadContext) {
  return context.logger.child({
    user_id: context.userID ? context.userID : "anon",
    user_email: context.user?.Email || "",
    user_name: context.user?.UserName || "",
  })
}

export function Public(func: (args: Args) => Promise<Response>) {
  return async (args: Args) => {
    let session = await getSessionID(args.request.headers.get("Cookie"))
    let sessionID = session.get("sessionID") || uuid.v4()

    const { userID, user, masquerador } = await getUser(args.request)
    args.context.db = await DB.client()
    args.context.sessionID = sessionID
    args.context.userID = userID
    args.context.user = user
    args.context.masquerador = masquerador || null
    args.context.logger = userLogger(args.context)

    let response: Response

    try {
      response = await func(args)
    } catch (error) {
      args.context.logger.warn("loader/action error?", {
        error: JSON.stringify(error),
        user_id: userID,
      })
      if (isRouteErrorResponse(error)) {
        response = json({ error: error.statusText }, { status: error.status, headers })
      } else if (error instanceof Response) {
        throw error
      } else if (error instanceof Error) {
        args.context.logger.error(error.message, { stack: error.stack })
        response = json({ error: error.message }, { status: 500, headers })
      } else {
        args.context.logger.error("unknown error", {
          message: JSON.stringify(error),
        })
        response = json({ error: JSON.stringify(error) }, { status: 500, headers })
      }
    }

    session.set("sessionID", sessionID)
    response.headers.set("X-Session-ID", sessionID)
    response.headers.append("Set-Cookie", await commitSessionID(session))

    return addUserHeaders(args.context, response)
  }
}

export function Private(func: (args: Args) => Promise<Response>) {
  return Public(async (args: Args): Promise<Response> => {
    const url = new URL(args.request.url)
    if (!args.context.user) {
      throw redirect(`/login?redirectTo=${url.pathname}${url.search && url.search}`)
    }
    return func(args)
  })
}

export function Admin(func: (args: Args) => Promise<Response>) {
  return Private(async (args: Args): Promise<Response> => {
    if (args.context.user!.Role !== 1) {
      throw json({}, { status: 401 })
    }

    return func(args)
  })
}

export function PublicApi(func: (args: Args) => Promise<Response>) {
  return Public(async (args: Args): Promise<Response> => {
    if (args.request.method == "OPTIONS") {
      throw json({}, { headers })
    }

    const { userID, user } = await getApiUser(args.request)
    args.context.userID = userID
    args.context.user = user
    args.context.logger = userLogger(args.context)
    return func(args)
  })
}

export function PrivateApi(func: (args: Args) => Promise<Response>) {
  return PublicApi(async (args: Args): Promise<Response> => {
    if (!args.context.user) {
      throw json({}, { status: 401 })
    }
    return func(args)
  })
}

export function AdminApi(loader: (args: Args) => Promise<Response>) {
  return PrivateApi(async (args: Args): Promise<Response> => {
    if (args.context.user!.Role !== 1) {
      throw json({ error: `User ${args.context.userID} is not an admin.` }, { status: 401 })
    }

    return loader(args)
  })
}
